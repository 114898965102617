export default class TargetCopyButton extends React.PureComponent
  
  constructor: (props) ->
    super(props)

    @tooltipInitialText = @props.i18n.initialText
    @tooltipSuccessText = @props.i18n.successText
    @tooltipSuccessText = @tooltipSuccessText.replace("%{icon}", "<i class=\"fas fa-check\"></i>")

    @copyTargetToClipboard = @copyTargetToClipboard.bind(this)

  componentDidMount: ->
    @rebindTooltip()

  rebindTooltip: ->
    # Destroy the existing tooltip, if any
    existingTooltip = Bootstrap.Tooltip.getInstance('#target-copy-button')
    existingTooltip.dispose() if existingTooltip # if error on BugSnag, look at this: https://github.com/twbs/bootstrap/issues/37474#issuecomment-1658773983

    # Rebind the tooltip
    new Bootstrap.Tooltip('#target-copy-button')

  copyTargetToClipboard: ->
    navigator.clipboard.writeText(@props.targetText)

    tooltip = Bootstrap.Tooltip.getInstance('#target-copy-button')
    tooltip.setContent({ '.tooltip-inner': @tooltipSuccessText })

    setTimeout( =>
      tooltip.setContent({ '.tooltip-inner': @tooltipInitialText })
      tooltip.hide()
    , 2000)

  render: ->
    classes = "btn btn-link"
    classes += " d-none" if !@props.targetText.length
    
    <button id="target-copy-button"
            className={classes}
            data-bs-toggle="tooltip"
            data-bs-placement="left"
            data-bs-html="true"
            data-bs-title={@tooltipInitialText}
            onClick={@copyTargetToClipboard}>
      <i className="far fa-copy"></i>
    </button>
