import RobotIcon      from '../icons/RobotIcon'
import onClickOutside from 'react-onclickoutside'

class EngineDropdown extends React.PureComponent
  constructor: (props) ->
    super(props)

    @state =
      open: false

    @openDropdown  = @openDropdown.bind(this)
    @closeDropdown = @closeDropdown.bind(this)

  handleClickOutside: (e) ->
    @closeDropdown()

  openDropdown: =>
    @setState(open: true) if !@state.open

  closeDropdown: ->
    @setState(open: false) if @state.open

  selectEngine: (engine, e) ->
    @closeDropdown()

    if @props.authorizedEngines.includes(engine)
      @props.selectEngine(engine)
    else
      @props.openModal('engine', e)

  render: ->
    <div className="engine-container">
      { @renderDropdownButton() if !@state.open }
      { @renderChoices()        if @state.open  }
    </div>

  renderDropdownButton: ->
    <button className="engine-button" tabIndex="-1" onClick={@openDropdown}>
      <RobotIcon />
      {@props.i18n.engines[@props.engine]}
      <i className="fas fa-angle-down"></i>
    </button>

  renderChoices: ->
    # Reorder engines to have the current one in first position
    engines = [@props.engine, ...@props.engines.filter((engine) => engine != @props.engine)]
    choices = engines.map((engine) =>
      isCurrent = engine == @props.engine

      classes = "engine-choice"
      classes += " active" if isCurrent

      <button className={classes} key={engine} onClick={@selectEngine.bind(this, engine)}>
        <RobotIcon />
        {@props.i18n.engines[engine]}
        {@renderSelectedIcon() if isCurrent}
      </button>
    )

    <div className="engine-list">
      {choices}
    </div>

  renderSelectedIcon: ->
    <i className="fas fa-check"></i>

export default onClickOutside(EngineDropdown)
