export default class WaitingListForm extends React.PureComponent
  constructor: (props) ->
    super(props)

    @state =
      email: ''
      step:  'edit' # ['edit', 'submitted', 'invalid', 'added']

    @updateEmail         = @updateEmail.bind(this)
    @submitEmail         = @submitEmail.bind(this)
    @triggerInputKeyDown = @triggerInputKeyDown.bind(this)

  updateEmail: (e) ->
    @setState(
      email: e.target.value
      step:  'edit'
    )

  triggerInputKeyDown: (e) ->
    if e.code == 'Enter'
      @submitEmail()

  submitEmail: (e) ->
    if @state.step == 'edit'
      @setState(step: 'submitted', =>
        params =
          email:   @state.email
          origin:  @props.origin  || 'none'
          choices: @props.choices || []
          locale:  @props.currentLocale

        http.post("/waiting_list.json", params, (data) =>
          if data.added
            @setState(step: 'added')
          else
            @setState(step: 'invalid')
        )
      )

  render: ->
    <div className="email-form">
      { @renderErrorMessage() if @state.step == 'invalid' }
      { @renderEmailField() }
      { @renderSubmitButton() }
      { @renderFootNote() }
    </div>

  renderErrorMessage: ->
    <p className="invalid-email">
      {@props.i18n.invalidEmail}
    </p>

  renderEmailField: ->
    classes =  'form-control'
    classes += ' invalid' if @state.step == 'invalid'

    <input className={classes}
           id="#{@props.formId}-email"
           type="email"
           name="email"
           placeholder={@props.i18n.emailPlaceholder}
           autoComplete="email"
           value={@state.email}
           onChange={@updateEmail}
           onKeyDown={@triggerInputKeyDown}
           disabled={['added', 'submitted'].includes(@state.step)} />

  renderSubmitButton: ->
    classes = if @state.step == 'added' then 'btn btn-success' else 'btn btn-primary'

    buttonText = switch
      when @state.step == 'added'     then @props.i18n.successfullyAdded
      when @state.step == 'submitted' then @props.i18n.adding
      else                                 @props.i18n.joinWaitingList

    <button type="submit"
            className={classes}
            onClick={@submitEmail}
            disabled={@state.step != 'edit' || @state.email == ''}>
      {buttonText}
    </button>

  renderFootNote: ->
    if @state.step == 'added'
      <p className="contact-us">
        {@props.i18n.reachOut}
        {' '}
        <a target="_blank" href="mailto:contact@neurooo.com">
          contact@neurooo.com
        </a>
      </p>
    else
      <p className="no-spam-note">
        {@props.i18n.noSpam}
      </p>
