import WaitingListForm from './WaitingListForm'

export default class CallToAction extends React.PureComponent

  constructor: (props) ->
    super(props)

    @state =
      choices: ['unlimited']

  updateChoices: (choice, e) ->
    if choice in @state.choices
      if @state.choices.length > 1 # At least one must be checked
        updatedChoices = @state.choices.filter((c) => c != choice)
        @setState(choices: updatedChoices)
    else
      @setState(choices: [...@state.choices, choice])

  render: ->
    <div className="cta container-fluid">
      <div className="container-lg">
        <div className="row section-title">
          <p className="secondary">
            {@props.i18n.stillWorking}
          </p>
          <p>
            {@props.i18n.whichFeature}
          </p>
        </div>

        <div className="row choices">
          { @renderChoices() }
        </div>

        <div className="row form-wrapper">
          <div className="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
            <h3>
              {@props.i18n.voteForAccess}
            </h3>

            <i className="fa-solid fa-chevron-down"></i>

            { @renderForm() }
          </div>
        </div>
      </div>
    </div>

  renderForm: ->
    <WaitingListForm formId="cta"
                     origin="feature-choice"
                     choices={@state.choices}
                     currentLocale={@props.currentLocale}
                     i18n={@props.i18n.waitingListForm} />

  renderChoices: ->
    [
      @renderChoice('unlimited'),
      @renderChoice('files'),
      @renderChoice('premises'),
      @renderChoice('tuning'),
      @renderChoice('edition'),
      @renderChoice('developers')
    ]

  renderChoice: (key) ->
    id      = "choice-#{key}"
    i18n    = @props.i18n.choices[key]
    checked = key in @state.choices

    labelClasses =  'h-100' # For choices on the same row to have the same height
    labelClasses += ' checked' if checked

    <div className="choice col-12 col-sm-6 col-xl-4" key={key}>
      <input type="checkbox"
             name="choice"
             id={id}
             className="btn-check"
             onChange={@updateChoices.bind(this, key)}
             checked={checked} />
      <label className={labelClasses}
             htmlFor={id} >
        <h4>
          {i18n.title}
        </h4>
        <p>
          {i18n.text}
        </p>
        {@renderCheckedIcon() if checked}
      </label>
    </div>

  renderCheckedIcon: ->
    <i className="far fa-circle-check"></i>
