import onClickOutside from 'react-onclickoutside'

import TargetWord from './target_sentence/TargetWord'
import Segmenter  from './../helpers/Segmenter'

class TargetSentence extends React.PureComponent
  constructor: (props) ->
    super(props)

    @highlightSentence   = @highlightSentence.bind(this)
    @unhighlightSentence = @unhighlightSentence.bind(this)

  handleClickOutside: (e) ->
    # This condition prevents entire sentence flicking if selecting other word or word alternative
    if !e.target.closest('.word')
      @props.setActiveSentence(-1)
      @props.setActiveWord(-1)

  highlightSentence: ->
    # Highlight active sentence on mouseEnter only if no active word (and sentence already highlighted somewhere)
    if @props.activeWordIndex == -1
      @props.setActiveSentence(@props.index)

  unhighlightSentence: ->
    # Unhighlight active sentence on mouseLeave only if no active word (and sentence already highlighted somewhere)
    if @props.activeWordIndex == -1
      @props.setActiveSentence(-1) # no more active sentence

  render: ->
    words = Segmenter.words(@props.sentence, @props.languageCode)

    renderedWords = words.map((word, wordIndex) =>
      <TargetWord key="#{wordIndex}-#{word}"
                  word={word}
                  index={wordIndex}
                  sentenceIndex={@props.index}
                  active={@props.active && @props.activeWordIndex == wordIndex}
                  wordAlternatives={@props.wordAlternatives}
                  wordAlternativesLoading={@props.wordAlternativesLoading}
                  setActiveSentence={@props.setActiveSentence}
                  setActiveWord={@props.setActiveWord}
                  selectWordAlternative={@props.selectWordAlternative} />
    )

    classes = 'sentence'
    classes += ' active' if @props.active

    <span className={classes}
          onMouseEnter={@highlightSentence}
          onMouseLeave={@unhighlightSentence}>
      {renderedWords}
    </span>

export default onClickOutside(TargetSentence)
