import GlobeIcon from '../icons/GlobeIcon'

export default class LocaleSwitcher extends React.PureComponent

  constructor: (props) ->
    super(props)

    @onChange = @onChange.bind(this)

  onChange:(e) ->
    path = window.location.pathname

    if path == '/'
      newPath = "#{e.target.value}"
    else
      newPath = path.replace("/#{@props.currentLocale}", "/#{e.target.value}")

    window.location.href = newPath

  render: ->
    options = @props.locales.map((locale) =>
      <option value={locale.code} key={locale.code} >
        {locale.name}
      </option>
    )

    <div className="locale-switcher">
      <GlobeIcon />
      <select className="form-select form-select-sm w-auto"
              value={@props.currentLocale}
              onChange={@onChange} >
        {options}
      </select>
    </div>
