import LoadingDots from '../../LoadingDots'

export default class WordAlternatives extends React.PureComponent
  MINIMUM_RIGHT_OFFSET = 16 # in px

  constructor: (props) ->
    super(props)

    @state =
      align: 'left'

    @ref = React.createRef()

    @dUpdatePosition = _.debounce(@updatePosition.bind(this), 50)

  componentDidMount: ->
    addEventListener('resize', @dUpdatePosition) # Debounced to avoid to trigger each pixel when resizing

  componentWillUnmount: ->
    removeEventListener('resize', @dUpdatePosition)

  componentDidUpdate: ->
    @updatePosition()

  selectWordAlternative: (wordAlternative, e) ->
    e.stopPropagation() # don't propagate to `selectWord` and reopen the list of word alternatives

    @props.selectWordAlternative(wordAlternative)

  updatePosition: ->
    if @ref.current
      browserWindowWidth = window.innerWidth
      alternativesWidth  = @ref.current.getBoundingClientRect().width
      parentLeftOffset   = @ref.current.parentElement.getBoundingClientRect().left

      rightOffset = browserWindowWidth - alternativesWidth - parentLeftOffset

      if rightOffset < MINIMUM_RIGHT_OFFSET
        @setState(align: 'right')
      else
        @setState(align: 'left')

  render: ->
    classes = "word-alternatives card"
    classes += " #{@state.align}-start"

    <ul ref={@ref} className={classes}>
      { @renderWordAlternativesLis() }
    </ul>

  renderWordAlternativesLis: ->
    if @props.loading
      <li className="words-loading">
        <LoadingDots visible={true} />
      </li>
    else
      @props.wordAlternatives.map((wordAlternative) =>
        liContent    = "#{wordAlternative}…"
        titleContent = if wordAlternative.length > 30 then liContent else '' # don't use title if word is small, only if it's long and has a chance to be cropped

        <li key={wordAlternative}
            onClick={@selectWordAlternative.bind(this, wordAlternative)}
            title={titleContent}>
          { liContent }
        </li>
      )
