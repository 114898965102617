import NavbarSlider from './navbar/NavbarSlider'
import NeuroLogo    from './logo/NeuroLogo'

export default class Navbar extends React.PureComponent
  constructor: (props) ->
    super(props)

    @state =
      mobileNavbarIsOpen: false

    # Use a reference for Bootstrap's collapsible navbar (mobile view)
    @collapseRef = React.createRef()

    # Bound method
    @changeMode = @changeMode.bind(this)

  componentDidMount: ->
    # We watch the opening and closing of Bootstrap's mobile navbar,
    # so we can synchronize our state with it
    @collapseRef.current.addEventListener('show.bs.collapse', (event) =>
      @setState(mobileNavbarIsOpen: true)
    )

    @collapseRef.current.addEventListener('hide.bs.collapse', (event) =>
      @setState(mobileNavbarIsOpen: false)
    )

  changeMode: (mode) ->
    # Close the mobile menu (if open) when changing mode
    if @state.mobileNavbarIsOpen
      Bootstrap.Collapse.getInstance(@collapseRef.current).hide()

    if @props.changeMode
      @props.changeMode(mode) # on main Neurooo page
    else
      window.location.href = "/#{@props.currentLocale}/#{mode}"; # on other pages

  render: ->
    <div className="navbar navbar-expand-md navbar-neurooo" role="navigation">
      <div className="container-lg navbar-container">
        <a href={"/#{@props.currentLocale}"} className="navbar-brand" title="Neurooo">
          <NeuroLogo/>
        </a>
        { @renderToggler() }
        { @renderMainNavbar() }
      </div>
    </div>

  renderToggler: ->
    <button className="navbar-toggler collapsed"
            ref={@togglerRef}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#main-navbar"
            aria-controls="main-navbar"
            aria-expanded="false"
            aria-label={@props.i18n.togglerLabel} >
      <i className="fas fa-bars"></i>
      <i className="fas fa-xmark"></i>
    </button>

  renderMainNavbar: ->
    <div className="navbar-collapse collapse" id="main-navbar" ref={@collapseRef}>
      { @renderSlider() }
      { @renderActions() }
    </div>

  renderSlider: ->
    modes = ['text', 'files', 'apps']

    <NavbarSlider modes={modes}
                  activeMode={@props.mode}
                  changeMode={@changeMode}
                  mobileNavbarIsOpen={@state.mobileNavbarIsOpen}
                  currentLocale={@props.currentLocale}
                  i18n={@props.i18n} />

  renderActions: ->
    if @props.openModal
      openModal = @props.openModal # on main Neurooo page
    else
      openModal = (action) => window.location.href = "/#{@props.currentLocale}/?modal=#{action}"; # on other pages

    <div className="actions">
      <a href="/#{@props.currentLocale}/?modal=api" className="link d-inline d-md-none d-lg-inline" type="button" onClick={openModal.bind(this, 'api')}>
        {@props.i18n.api}
      </a>
      &nbsp;
      <a href="/#{@props.currentLocale}/blog" className="link-blog link d-inline d-md-none d-lg-inline" type="button">
        { @props.i18n.blog   }
        { @renderBlogBadge() }
      </a>
      &nbsp;
      <a href="/#{@props.currentLocale}/?modal=early" className="pro-button" type="button" onClick={openModal.bind(this, 'early')}>
        { @props.i18n.early }
      </a>
    </div>

  renderBlogBadge: ->
    if @props.blogNotification
      <span className="badge rounded-pill">
        1 <span className="visually-hidden">{@props.i18n.unreadArticles}</span>
      </span>
