import LocaleSwitcher from './footer/LocaleSwitcher'

export default class Footer extends React.PureComponent

  constructor: (props) ->
    super(props)

  render: ->
    year = new Date().getFullYear()

    <footer className="container-fluid footer-neurooo">
      <div className="container-lg">
        <div className="row">
          <div className="col-12 col-md-2 order-md-2 col-locale-switcher">
            <LocaleSwitcher locales={@props.locales}
                            currentLocale={@props.currentLocale} />
          </div>

          <div className="col-12 col-md-5 order-md-1 d-flex align-items-center">
            <span className="text-start">
              &copy; {year} <a href="/">Neurooo.com</a>
              { @renderSeparator(' • ') }
              <a target="_blank" href="mailto:contact@neurooo.com">
                {'Contact'}
              </a>
            </span>
          </div>

          <div className="col-12 col-md-5 order-md-3 d-flex align-items-center">
            <span className="text-start flex-md-fill text-md-end">
              <a href={"/#{@props.currentLocale}/privacy-policy"} className="text-nowrap">
                {@props.i18n.privacyPolicy}
              </a>
              { @renderSeparator(' • ') }
              <a href={"/#{@props.currentLocale}/terms-and-conditions"} className="text-nowrap">
                {@props.i18n.termsOfService}
              </a>
            </span>
          </div>
        </div>
      </div>
    </footer>

  renderSeparator: (separator) ->
    <span className="separator">{"#{separator}"}</span>
