import WordAlternatives from './target_word/WordAlternatives'

export default class TargetWord extends React.PureComponent
  constructor: (props) ->
    super(props)

    @selectWord = @selectWord.bind(this)

  selectWord: (e) ->
    @props.setActiveSentence(@props.sentenceIndex, =>
      @props.setActiveWord(@props.index, @props.word)
    )

  render: ->
    # Regex to detect words in any alphabet
    regex = new RegExp(/[\p{Letter}\p{Mark}]+/, 'gu')

    if @props.word.match(regex)
      @renderWord()
    else
      @props.word

  renderWord: ->
    classes = 'word'
    classes += ' active' if @props.active

    <span className={classes}
          onClick={@selectWord}>
      {@props.word}
      {@renderWordAlternatives()}
    </span>

  renderWordAlternatives: ->
    if @props.active
      <WordAlternatives wordAlternatives={@props.wordAlternatives}
                        loading={@props.wordAlternativesLoading}
                        selectWordAlternative={@props.selectWordAlternative} />
