export default class NavbarSlider extends React.PureComponent
  constructor: (props) ->
    super(props)

    @state = {
      initialized: false
      pillWidth:   0
      pillLeft:    0
    }

    @sliderRef = React.createRef()

  componentDidMount: ->
    # Switching from the static SSR view to the dynamic front-end "sliding pill"
    @initializeSlider()

  componentDidUpdate: ->
    # Initialization is only possible if the elements are visible,
    # which is not the case on mobile (hidden in buger menu),
    # so we watch for a change in the "mobileNavbarIsOpen" prop to initialize if necessary
    if !@state.initialized
      @initializeSlider()

  initializeSlider: ->
    activeElement = $(@sliderRef.current).find('.active')

    if activeElement.css('width') != '0px'
      @setState(
        pillWidth: @elementWidth(activeElement)
        pillLeft:  @elementLeft(activeElement)
      )

      # Inelegant, but this is to avoid a "flash" when switching from
      # the SRR pill (button background) to the front-end pill (span sliding behind)
      setTimeout(() =>
        @setState(initialized: true)
      , 150)

  elementWidth: (element) ->
    element.css('width')

  elementLeft: (element) ->
    if element.position()
      element.position().left + parseInt(element.css('margin-left'))

  changeMode: (mode, e) ->
    e.preventDefault() # don't really follow href

    targetElement = $(e.target)

    @setState({
      pillWidth: @elementWidth(targetElement)
      pillLeft:  @elementLeft(targetElement)
    }, @props.changeMode.bind(this, mode))

  render: ->
    classes = "modes"
    classes += " ssr" if !@state.initialized

    <div className={classes} ref={@sliderRef}>
      { @props.modes.map((mode) => @renderMode(mode)) }
      { @renderPill() if @props.modes.includes(@props.activeMode) }
    </div>

  renderMode: (mode) ->
    classes = if mode == @props.activeMode then 'active' else ''

    <a href="/#{@props.currentLocale}/#{mode}"
       key={mode}
       className={classes}
       type="button"
       onClick={@changeMode.bind(this, mode)}>
      {@props.i18n[mode]}
    </a>

  renderPill: ->
    classes = "pill"
    classes += " initialized" if @state.initialized

    style =
      width: @state.pillWidth
      left:  @state.pillLeft

    <span className={classes} style={style} />
