export default class Summary extends React.PureComponent

  componentDidMount: ->
    @rebindPopover()

  componentDidUpdate: (prevProps, prevState, snapshot) ->
    @rebindPopover()

  rebindPopover: ->
    if $('.summary .summary-popover').length
      options =
        html:     true
        sanitize: false

      new Bootstrap.Popover($('.summary .summary-popover'), options)

  computeValues: ->
    billableProjects = _.filter(@props.projects, (project) => project.isOwner && !project.openSource)

    activeProjects   = _.filter(billableProjects, (project) => !project.archived)
    archivedProjects = _.filter(billableProjects, (project) => project.archived)

    activeBillableKeysCount   = _.sumBy(activeProjects,   (project) => project.billableKeysCount)
    archivedBillableKeysCount = _.sumBy(archivedProjects, (project) => project.billableKeysCount)

    activePrice   = activeBillableKeysCount   * @props.activeSegmentPrice
    archivedPrice = archivedBillableKeysCount * @props.archivedSegmentPrice
    totalPrice    = activePrice + archivedPrice

    discountsReduction = 0.0

    for line in @props.discounts
      # percentage or flat discount
      if _.includes(line, '%')
        discountLine = totalPrice * parseFloat(line) / 100.0
      else
        discountLine = parseFloat(line)

      discountsReduction += _.round(discountLine, 2)

    return {
      activeBillableKeysCount:   activeBillableKeysCount,
      archivedBillableKeysCount: archivedBillableKeysCount,
      activePrice:               activePrice,
      archivedPrice:             archivedPrice,
      totalPrice:                totalPrice
      discountsReduction:        discountsReduction
      discountedPrice:           _.max([0.0, totalPrice - discountsReduction])
    }

  render: ->
    computedValues  = @computeValues()
    hasBillableKeys = computedValues.activeBillableKeysCount + computedValues.archivedBillableKeysCount > 0

    if @props.projects.length > 0 && !@props.hasActiveFilters() && hasBillableKeys
      <div className="summary">
        { @renderTable(computedValues) }
      </div>
    else
      []

  renderTable: (values) ->
    description = """
      <p style="font-size:12px">
        You will be billed monthly, based on
        the number of translated keys in all projects for
        which you are the owner.
      </p>
      <p style="font-size: 12px; margin-bottom: 0px">
        This amount is a snapshot that can evolve over time.
      </p>
    """

    # Ignore the fact that "frozen keys" exist if nothing frozen
    hasAnyFrozenKeys = values.archivedBillableKeysCount > 0
    activeKeysTitle  = if hasAnyFrozenKeys then 'Active keys:' else "Keys:"

    <table className="summary-popover"
           data-bs-toggle="popover"
           data-bs-placement="right"
           data-bs-trigger="hover"
           data-bs-offset="0,12"
           data-bs-title="Billable Amount"
           data-bs-content={description}>
      <tbody>
        <tr>
          <td className="state">{activeKeysTitle}</td>
          <td className="separator"></td>
          <td>
            {values.activeBillableKeysCount}
          </td>
        </tr>

        {
          hasAnyFrozenKeys &&
          <tr>
            <td className="state">Frozen keys:</td>
            <td className="separator"></td>
            <td>
              {values.archivedBillableKeysCount}
            </td>
          </tr>
        }

        <tr>
          <td className="state">Monthly Price:</td>
          <td className="separator"></td>
          <td>{@props.currencySymbol}{values.totalPrice.toFixed(2)}</td>
        </tr>

        { @renderReduction(values) }
      </tbody>
    </table>

  renderReduction: (values) ->
    if values.discountsReduction > 0
      borderTop = { borderTop: '1px solid #cccccc' }

      [
        <tr key="discount" style={{ color: 'green' }}>
          <td className="state">Discount:</td>
          <td className="separator"></td>
          <td>- {@props.currencySymbol}{values.discountsReduction.toFixed(2)}</td>
        </tr>
      ,
        <tr key="discounted-price">
          <td className="state" style={borderTop}></td>
          <td className="separator" style={borderTop}></td>
          <td style={borderTop}><strong>{@props.currencySymbol}{values.discountedPrice.toFixed(2)}</strong></td>
        </tr>
      ]
